/**@jsx jsx*/
import { useContext, useState } from "react";
import { jsx, Heading } from "theme-ui";
import { AppContext } from "../../context/app-context";
import { JobOpening } from "./job-opening";
import { Job } from "../../apollo/schemas/api";

export function JobOpenings({ active }) {
  const [activeId, setActiveId] = useState<string[]>([]);
  function handleClick(id) {
    setActiveId((c) =>
      c.includes(id) ? c.filter((cId) => cId !== id) : [...c, id]
    );
  }
  const { locale } = useContext(AppContext);
  return (
    <div
      sx={{
        px: 3,
        display: "grid",
        gap: 3,
        mb: [4],
        maxWidth: ["960px", null, null, null, "1280px"],
        mx: "auto",
        textAlign: "left",
      }}
    >
      <Heading
        as="h2"
        sx={{ textTransform: "uppercase", my: [2, 4], textAlign: "center" }}
      >
        {locale === "es-PE" ? "Convocatorias abiertas" : "Job offers"}
      </Heading>
      {(active as Job[]).map((jobOffer) => (
        <JobOpening
          key={jobOffer._id}
          job={jobOffer}
          active={activeId.includes(jobOffer._id)}
          handleClick={handleClick}
        />
      ))}
    </div>
  );
}
