/**@jsx jsx*/
import { Button, Heading, jsx, Spinner } from "theme-ui";
import { format } from "date-fns";
import { navigate } from "gatsby";

import { Job, User } from "../../apollo/schemas/api";
import { Dollar, Time, Atom, Building, Calendar, Map } from "../../icons";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CURRENT_USER } from "../../apollo/queries/users/getCurrentUser";
import { APPLY_JOB } from "../../apollo/mutations/jobs/applyJob";
import { GET_ALL_JOBS } from "../../apollo/queries/jobs/getAllJobs";
import { useEffect, useState } from "react";

export function JobOpening({
  job,
  active,
  handleClick,
}: {
  job: Job;
  active: boolean;
  handleClick: (id: string) => void;
}) {
  const [jobApplication] = useMutation(APPLY_JOB, {
    onCompleted: () => {
      navigate("/app/");
    },
    refetchQueries: [{ query: GET_ALL_JOBS }],
    awaitRefetchQueries: true,
    onError: (err) => {
      if (err.message === "Necesitas ser consultor") {
        alert("Debes ir a tu perfil y configurarlo como consultor");
      }
      if (err.message === "Necesitas completar modulos") {
        alert("Debes ir a tu perfil y agregar experiencia en Modulos SAP");
      }
      // if (err.message === "Necesitas completar experiencias") {
      //   alert(
      //     "Debes ir a tu perfil y agregar referencias para validar tu experiencia"
      //   );
      // }
      if (err.message === "Necesitas completar rubros") {
        alert("Debes ir a tu perfil y agregar rubros de experiencia");
      }
      if (err.message === "Necesitas completar skills") {
        alert("Debes ir a tu perfil y agregar tus habilidades");
      }
    },
  });
  const [showJob, setShowJob] = useState<boolean>(true);
  const { data, loading } = useQuery<{ getCurrentUser: User }>(
    GET_CURRENT_USER
  );
  console.log({ showJob });
  useEffect(() => {
    if (data && data.getCurrentUser) {
      console.log({ data });
      data.getCurrentUser?.applications &&
        data.getCurrentUser.applications.length > 0 &&
        data.getCurrentUser.applications.forEach((app) => {
          console.log(app._id);
          console.log(job._id);
          if (app._id === job._id) {
            setShowJob(false);
          }
        });
    }
  }, [data, loading]);
  const date = format(new Date(job.updatedAt["@ts"]), "dd/MM/yyyy");
  const handleCandidate = async (id) => {
    const isLoggedIn = !!localStorage.getItem("token");
    if (!isLoggedIn) {
      navigate("/app/");
      return;
    }
    const isUser = data?.getCurrentUser?.role === "PUBLIC";
    if (!isUser) {
      alert("Solo los usuarios pueden postular");
      return;
    }
    const finishOnboarding = data?.getCurrentUser?.onboarding.finished ?? false;
    if (!finishOnboarding) {
      alert("Necesitas terminar tu perfil");
      return;
    }
    try {
      await jobApplication({
        variables: {
          id,
        },
      });
    } catch (err) {
      if (err.message === "Necesitas ser consultor") {
        alert("Debes ir a tu perfil y configurarlo como consultor");
      }
      if (err.message === "Necesitas completar modulos") {
        alert("Debes ir a tu perfil y agregar experiencia en Modulos SAP");
      }
      // if (err.message === "Necesitas completar experiencias") {
      //   alert(
      //     "Debes ir a tu perfil y agregar referencias para validar tu experiencia"
      //   );
      // }
      if (err.message === "Necesitas completar rubros") {
        alert("Debes ir a tu perfil y agregar rubros de experiencia");
      }
      if (err.message === "Necesitas completar skills") {
        alert("Debes ir a tu perfil y agregar tus habilidades");
      }
    }
  };
  if (!data && loading) {
    return (
      <div
        sx={{
          display: ["grid"],
          placeItems: ["center"],
          position: ["fixed"],
          top: [0],
          right: [0],
          left: [0],
          bottom: [0],
          zIndex: -1,
        }}
      >
        <Spinner />
      </div>
    );
  }
  return (
    <div
      sx={{
        borderRadius: "24px",
        bg: active ? "background" : "footer",
        p: 3,
        color: "text",
        border: (t) => `2px solid ${t.colors.dark[0]}`,
        maxWidth: "1280px",
        width: "100%",
        mx: "auto",
      }}
    >
      <div sx={{ width: "100%" }}>
        <Heading
          as="h3"
          sx={{
            display: "grid",
            gridTemplateColumns: ["1fr 16px", !active && "3fr 1fr 16px"],
            height: !active && ["52px", "auto"],
            overflow: "hidden",
            cursor: "pointer",
            borderBottom: (t) => active && `1px solid ${t.colors.dark[3]}`,
          }}
          onClick={() => {
            handleClick(job._id);
          }}
        >
          {job.title}{" "}
          <span sx={{ display: ["none", !active && "block"] }}>{date}</span>
          <span sx={{ textAlign: "right" }}>{!active ? "+" : "-"}</span>
        </Heading>
      </div>
      {active && (
        <div
          sx={{
            display: [null, "grid"],
            gridTemplateColumns: [null, "1fr 1fr"],
            pt: [3],
          }}
        >
          <div>
            <Heading as="h4">Descripción:</Heading>
            {job.description}
            <div dangerouslySetInnerHTML={{ __html: job.content }} />
          </div>

          <div
            sx={{
              bg: "background",
              borderRadius: "16px",
              boxShadow: "0 0 10px rgba(32, 99, 244, 0.2)",
              p: 2,
              height: "max-content",
              display: "grid",
              width: "300px",
              ml: "auto",
            }}
          >
            <div
              sx={{
                display: "grid",
                gridTemplateColumns: "24px 1fr",
                gridAutoRows: "max-content",
                alignItems: "center",
                gap: 2,
                py: 2,
                borderBottom: (t) => `1px solid ${t.colors.light[2]}`,
              }}
            >
              <span sx={{ color: "header" }}>
                <Calendar />
              </span>
              <span sx={{ fontSize: 0 }}>Publicado el {date}</span>
              <span sx={{ color: "header" }}>
                <Map />
              </span>
              <span sx={{ fontSize: 0 }}>
                {job.address?.city ?? "Lima"} - {job.address?.country ?? "Perú"}
              </span>
            </div>
            <div
              sx={{
                display: "grid",
                gridTemplateColumns: "24px 1fr",
                gridAutoRows: "max-content",
                alignItems: "center",
                gap: 2,
                py: 2,
              }}
            >
              <span sx={{ color: "primary" }}>
                <Time />
              </span>
              <span sx={{ fontSize: 0 }}>
                {job.type === "PART" ? "Part-time" : "Full-time"}
              </span>
              <span sx={{ color: "primary" }}>
                <Dollar />
              </span>
              <span sx={{ fontSize: 0 }}>
                {job.salaryRange.length > 1
                  ? job.salaryRange
                  : "No especificado"}
              </span>
              <span sx={{ color: "primary" }}>
                <Atom />
              </span>
              <span sx={{ fontSize: 0 }}>
                {job.jobCategory ?? job.consultantJob ? "Consultoría SAP" : ""}
              </span>
              <span sx={{ color: "primary" }}>
                <Building />
              </span>
              <span sx={{ fontSize: 0 }}>Remoto temporal</span>
            </div>
            {showJob && (
              <Button onClick={() => handleCandidate(job._id)}>Postular</Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
