/**@jsx jsx*/
import { Heading, jsx, Label } from "theme-ui";
import { graphql } from "gatsby";
import { useContext, useEffect } from "react";
import { JobOpenings } from "../components/job-openings/job-openings";
import { Layout } from "../components/layout/main-layout";
import { AppContext } from "../context/app-context";
import { Hero } from "../components/distribution/hero";
import { FormContainer } from "../components/form/form";
import { Seo } from "../components/seo/seo";

export default function JoinusTemplate({ pageContext, data }) {
  /* LOCALES SET UP*/
  const lang = pageContext.locale === "es-PE" ? "es" : "en";

  const { setLocale, setSecureUrl } = useContext(AppContext);

  useEffect(() => {
    setLocale(pageContext.locale);
  }, []);
  const uploadFile = async (e) => {
    const files = e.target.files;
    const data = new FormData();
    data.append("file", files[0]);
    data.append("upload_preset", "praxisnet");
    const res = await fetch(
      "https://api.cloudinary.com/v1_1/praxisnet/image/upload",
      {
        method: "POST",
        body: data,
      }
    );
    const file = await res.json();
    if (file) {
      setSecureUrl(file.secure_url);
    }
  };

  const page = data.contentfulPage;
  const { title, slug, content, image } = page;

  const openedOffers = data.fauna.getAllPendingJobs;

  return (
    <Layout>
      <Seo
        title={title}
        image={{ src: image.fluid.src, height: 300, width: 300 }}
        lang={lang}
      />
      <Hero
        image={image.fluid}
        slug={slug}
        title={title}
        description={content?.content}
      />
      <JobOpenings active={openedOffers} />
      {/* <div
        sx={{
          bg: "header",
          display: "grid",
          placeContent: "center",
          py: 5,
          px: [3, null, null, 5],
        }}
      >
        <Heading
          as="h3"
          sx={{
            variant: "text.title",
            textAlign: "center",
            color: "background",
            fontSize: [4, null, null, 4],
          }}
        >
          ¿Quieres ser parte de Nuestro Equipo?
          <br />
          Déjanos tu CV
        </Heading>

        <Label
          sx={{
            bg: "primary",
            color: "white",
            width: "196px",
            height: "56px",
            borderRadius: ["10px", null, null, "62px"],
            mx: "auto",
            fontSize: 2,
            display: "flex",
            justifyContent: "center",
            fontWeight: "heading",
            alignItems: "center",
            cursor: "pointer",
            "&:hover": {
              bg: "background",
              color: "header",
            },
          }}
        >
          Seleccionar archivo
          <input type="file" sx={{ display: "none" }} onChange={uploadFile} />
        </Label>
      </div> */}
    </Layout>
  );
}

export const query = graphql`
  query($locale: String, $slug: String) {
    contentfulPage(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      title
      slug
      content {
        content
      }
      image {
        fluid(maxWidth: 2500, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      page_content {
        title
      }
    }
    fauna {
      getAllPendingJobs {
        title
        type
        updatedAt
        salaryRange
        position
        description
        content
        _id
        address {
          city
          country
        }
      }
    }
  }
`;
